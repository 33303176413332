.css-hvagwd {
  stroke: #513fca !important;
}
#profile-steps {
  padding: 0 60px;
}

/* #title {
    padding: ;
}
 */
.css-kcf2wt {
  padding: 15px 0;
}

.custom-btn {
  width: 45% !important;
}

/* TABLET */

@media only screen and (max-width: 768px) {
  .custom-btn {
    /* margin: 0 35px !important; */
    width: 100% !important;
  }
}

/* MOBILE SCFREEN */

@media only screen and (max-width: 430px) {
  #profile-steps {
    padding: 0;
  }
  form {
    margin: 30px;
  }

  .hide-show {
    display: none;
  }
  .css-kcf2wt {
    background-color: #f5f5f6;
    width: 100%;
    padding: 15px 10px;
  }
  #inner-form{
    background-color: white;
  }
  .css-h3zpu3 {
    padding: 0 !important;
    margin: 0 !important;

    background-color: #F2F2F5;
  }

  .custom-btn {
    /* margin: 0 35px !important; */
    width: 95% !important;
  }
}
