/* CSS */
:root { font-family: 'Inter', sans-serif; }
@supports (font-variation-settings: normal) {
  :root { font-family: 'Inter var', sans-serif; }
}

/** 
css for loqate component
**/
.loqate {
    width: 100%;
}

.loqate-list {
    background-color: hsl(0, 0%, 100%);
    border-radius: 4;
    box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
    box-sizing: border-box;
    color: #3a4559;
    margin-bottom: 8;
    margin-top: 8;
    position: absolute;
    top: 100%;
    overflow-y: scroll;
    width: 600px;
    max-height: 200px;
    z-index: 10000 !important;
}

.loqate-list-item {
    cursor: pointer;
    margin-bottom: 4;
    margin-top: 4;
    color: #3a4559;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    background-color: transparent;
    box-sizing: border-box;
    color: inherit;
    cursor: default;
    display: block;
    font-size: inherit;
    padding: 8px 12px;
    user-select: none;
    width: 100%;
    z-index: 1000 !important;
}

react-loqate-default-list {
    background-color: hsl(0, 0%, 100%);
    border-radius: 4;
    box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
    box-sizing: border-box;
    color: #3a4559;
    margin-bottom: 8;
    margin-top: 8;
    position: absolute;
    top: 100%;
    overflow-y: scroll;
    width: 600px;
    max-height: 200px;
    z-index: 1000 !important;
}


