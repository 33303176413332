.control-dots {
    margin: -81px 0 !important;
    height: 100px !important;

}

.dot {
    background-color: #513FCA !important;
}


/* .carousel-root {
    height: 530px;
} */

/* 

.carousel {
    height: 100%;
}



@media only screen and (min-width: 1440px) {
    .carousel-root {
        height: 390px;
    }
}

@media only screen and (max-width: 1024px) {
    .carousel-root {
        height: 275px;
    }
}

@media only screen and (max-width: 959px) {
    .carousel-root {
        height: 630px;
    }
}

@media only screen and (max-width: 768px) {
    .carousel-root {
        height: 490px;
    }
}

@media only screen and (max-width: 425px) {
    .carousel-root {
        height: 320px;
    }
}

@media only screen and (max-width: 375px) {
    .carousel-root {
        height: 280px;
    }
}

@media only screen and (max-width: 320px) {
    .carousel-root {
        height: 270px;
    }
} */