/* STEPS CLASSSES */
.css-hvagwd {
  stroke: #513fca !important;
}

.css-1m5zlm1[data-highlighted] { 
  background-color: #39D98A !important;
  border:4px solid rgba(226, 243, 236, 0.62) !important;
}
.css-1m5zlm1  {
background-color: rgb(173, 173, 173) !important; 
border: 2px solid #6F7A85 !important;
}
.css-1m5zlm1[aria-current]:not([aria-current="false"]) {
  background-color: #513fca !important;
  border:4px solid #513fca !important;
}
.css-1v9c4ip{
  color: white !important
  } 

#profile-steps {
  padding: 0 60px;
}

/* #title {
    padding: ;
}
 */
.css-kcf2wt {
  padding: 15px 0;
}

.custom-btn {
  width: 45% !important;
}

/* TABLET */

@media only screen and (max-width: 960px) {
  .css-1rqr5oy {
    padding: 0 15px !important;
  }
  .custom-btn {
    /* margin: 0 35px !important; */
    width: 100% !important;
  }
}

/* MOBILE SCFREEN */

@media only screen and (max-width: 430px) {
  #profile-steps.onboarding-profile-steps {
    padding: 0;
  }
  form {
    margin: 30px;
  }

  .hide-show {
    display: none;
  }
  
  .css-kcf2wt {
    background-color: #f5f5f6;
    width: 100%;
    padding: 0 10px;
    height: 50px;
  }
  
  #inner-form{
    background-color: white;
  }
  .css-h3zpu3[data-highlighted] {
    padding: 15px !important;
    margin-left: 25px !important;
    background-color: #f5f5f6 !important;
  }


  .custom-btn {
    /* margin: 0 35px !important; */
    width: 95% !important;
  }
}

@media only screen and (max-width: 689px) {
  .css-ip9or9{
    width: 100% !important;
  }
  #profile-steps {
    padding: 0 !important;
  }
  form {
    margin: 20px !important;
  }
  .css-xfjvse {
    width: 100% !important;
  }

  .hide-show {
    display: none !important;
  }
  
  .css-kcf2wt {
    background-color: #f5f5f6;
    width: 100%;
    padding: 0 5px !important;
    height: 50px;
  }
  
  #inner-form{
    background-color: white;
    width: 100% !important;
  }
  .css-h3zpu3[data-highlighted] {
    padding: 15px !important;
    margin-left: 20px !important;
    margin: 0px;
    background-color: #f5f5f6 !important;
    border-left: 2px solid #513fca !important;
  }
  .css-h3zpu3{
    margin: 0 !important;
    border: none;
    padding: 0 !important;
  }

  .custom-btn {
    /* margin: 0 35px !important; */
    width: 95% !important;
  }
}
