.chakra-react-select__indicators {
  display: none !important;
}
.chakra-react-select__control {
  padding: 14px !important;
  border-radius: 16px !important;
}
.search-bar > div > input {
  padding-top: 27px;
  padding-bottom: 27px;
  border-radius: 16px;
}
.search-bar > div > .chakra-input__right-element {
  padding-top: 30px;
  padding-bottom: 30px;
}
.search-bar > div:last-child {
  z-index: 1;
  position: absolute;
  width: 100%;
}
.blink {
  animation: blink 1s steps(5, start) infinite;
  -webkit-animation: blink 1s steps(5, start) infinite;
}
@keyframes blink {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink {
  to {
    visibility: hidden;
  }
}
